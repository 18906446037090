import React,{useState} from "react";
import { List, ListItem, Collapse,Typography,
   ListItemText, Divider, } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {findNumberCad} from '../funciones/FindNumber'
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Listas = ({funcionAbrir}) => {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [mostrarUsu, setMostrarUsu] = useState(false);
  const [verTipoUsu, setVerTipoUsu] = useState(false);
  const[usrID,setUsrId]=  useState(0);
  const[usrTipo,setUsrTipo]= useState(0);
  const auxnvl = localStorage.getItem("Tipo");
  const cadena = localStorage.getItem("Operacion")+"|";
  const nvl = auxnvl !== null && parseInt(auxnvl) !== 0 ? parseInt(auxnvl):0
  React.useEffect(() => {
    const auxOpera = localStorage.getItem("Operacion");
    
    const usuID = localStorage.getItem('UsuId');
    let aux1=usuID!== null && parseInt(usuID) !== 0 ? parseInt(usuID):0
    setUsrId(aux1)
    let aux2=nvl!== null && parseInt(nvl) !== 0 ? parseInt(nvl):0
    setUsrTipo(aux2)
    if (nvl !== null && parseInt(nvl) !== 2) {
      setVerTipoUsu(true);
    } else {
      setVerTipoUsu(false);
    }

    let auxValida = false;
    if (auxOpera.indexOf("0") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("1") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("2") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("3") !== -1) {
      auxValida = true;
    }
    /* if (auxValida) 
      { setMostrarUsu(true)
      } 
      else 
      { setMostrarUsu(false)
      } */
    if (parseInt(nvl) !== 2) {
      setMostrarUsu(true);
    } else {
      setMostrarUsu(false);
    }
  }, []);

  const cambiar = (opcion) => {
    if (opcion === 1) 
    { setOpen(!open);
    } 
    else{
      if (opcion === 2) 
      { setOpen2(!open2);
      } 
      else{
        if (opcion === 3) 
        { setOpen3(!open3);
        } 
        else{
          if (opcion === 4) 
          { setOpen4(!open4);
          }
        }
      }
    }
  };

  const nuevaVentana = (elLink) => {
    funcionAbrir()
    history.push(elLink);
  };

  /* const contacto=(e)=> {
    e.preventDefault()        
  } */

  const ItemLista = (link, descripcion) => {
    return (
    <ListItem button className={classes.nested}
      onClick={() => nuevaVentana(link)}
    >
      <ListItemText
        secondary={
          <Typography variant="body" style={{ color: "white" }}>
            {descripcion}
          </Typography>
        }
      />
    </ListItem>
    );
  };

  



const permUsu = usrID === 203 || usrID === 204 || usrID === 205 || usrID === 206 ? false:true
  const perEdo = nvl  !== 7  ? true: false
  const perRest = nvl  !== 2  ? true: false
  const permEsp = usrID === 138 || usrID === 139 ? true:false
  const externo = nvl  !== 8 ? true: false
  return (
  <div>
    <List component="nav">
      <ListItem button onClick={() => cambiar(1)}>
        <ListItemText primary="Menu" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/*  verTipoUsu && usrTipo !== 7  && externo ? ItemLista("/inicio", "Inicio") : null */}
          {findNumberCad('|4|',cadena) ?ItemLista("/lista-promovido", "Promovidos"):null}
          {  usrID === 1 ||  nvl === 1 || findNumberCad('|4|',cadena)? ItemLista("/lista-publico", "Publico SiempreContigo") : null }
          {/*  usrTipo !== 7 && externo ? ItemLista("/lista-promovido", "Promovidos"): */null}
          { /* usrTipo === 7 && externo ? ItemLista("/perfil-externo", "Promovidos"):null */}
          { /* (usrID === 1 ||  (findNumberCad('|10|',cadena)) ? ItemLista("/registro-previo", "Militante") : null  */}
          {(usrID === 1 ||   findNumberCad('|34|',cadena)) ? ItemLista("/solicitudes-atencion", "Solicitudes de Atención") :null  }
          {/* (usrID === 1 ||   findNumberCad('|40|',cadena)) ? ItemLista("/asistencia", "Asistencia") :null  */ }

          { (usrID === 1 ||  findNumberCad('|3|',cadena))? ItemLista("/lista-usuario", "Usuarios") : null}
          {/*  (usrID === 1 ||  findNumberCad('|13|',cadena)) ? ItemLista("/apoyo", "Apoyos") :null   */}
          {  (usrID === 1 ||  findNumberCad('|7|',cadena)) ? ItemLista("/recorrido", "Recorridos") :null    }
          {  (usrID === 1 ||  findNumberCad('|16|',cadena)) ? ItemLista("/estructura", "RG / RC") :null    }
          {  usrID === 1 ||  findNumberCad('|24|',cadena) ? ItemLista("/notificacion", "Notificación") : null }
          {  (usrID === 1 ||findNumberCad('|28|',cadena))? ItemLista("/mapa-secc", "Mapa Secciones") : null }
          
        </List>
        <Divider />        
      <List component="div" disablePadding>            
        {  /* findNumberCad('|20|',cadena) || usrID===1  ? ItemLista("/incidencia", "Incidencias") : null   */}
        { /* (usrID === 1 ||  nvl  === 1 ||  nvl  >= 7 )  ? ItemLista("/estafeta", "Estafeta") : null  */}
        { /* (usrID === 1 ||  findNumberCad('|18|',cadena)) ? ItemLista("/comite", "Comités") : null */  }
        {usrID === 1  || findNumberCad('|29|',cadena) ? ItemLista("/prep", "Votación") : null   }
        { /* usrID === 1 ||  nvl === 1 ||  nvl === 7 ||nvl === 9 ||nvl === 10  ? ItemLista("/organizacion", "Organización") : null   */}
        {   usrID === 1 ||  findNumberCad('|31|',cadena)? ItemLista("/carga-total", "Carga de Trabajo") : null   } 
        {   usrID === 1 ||  findNumberCad('|32|',cadena)   ? ItemLista("/avance-general", "Avance General") : null   }  
        {   usrID === 1 ||  findNumberCad('|32|',cadena)   ? ItemLista("/promovido-usuario", "Exportación de Promovidos") : null   }  
        {  /*  usrID === 1 ||  findNumberCad('|32|',cadena)   ? ItemLista("/avance-solicitud", "Avance Solicitud") : null   */ }      
        { usrID === 1 ||  findNumberCad('|33|',cadena)? ItemLista("/limpiar-cache", "Cache") : null  }      
      </List> 
      </Collapse>
             
      {/*<ListItem button onClick={contacto} >                    
          <ListItemText  
            secondary={<Typography variant="body2" style={{ color: 'white' }}>
            Contacto</Typography>}
          />                    
      </ListItem> */}
    </List>
  </div>
  );
};

export default Listas;
