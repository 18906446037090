import React,{useState,useEffect} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import {Box, LinearProgress, FormControlLabel,
   Typography, Checkbox} from "@material-ui/core";
//import Reporte from './Reporte'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
//import RepSudoku240130 from "./RepSudoku240130.json";
//import SelectEdo from '../usuario/SelectEdo';
import { obtenDispo } from '../funciones/IdDispositivo';
import SelectList from '../generales/SelectList';
import TablaAgrupamiento from './TablaAgrupa';
import TablaPromovidos from './TablaPromov';
//import TablaTotal from './TabEdoTot';
//import Graficas from './Graficas';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import TablaCumple from './TablaCumple';
const RepoSudoku = () => {
  let history = useHistory();    
  const colFondo=process.env.REACT_APP_Fondo_Color
  const source = axios.CancelToken.source();  
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');
  const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
  const auxDispV =obtenDispo()
  const {usuario,guardarUsuario}=React.useContext(AuthContext);  
  const {generales,guardarGenerales}= React.useContext(GeneralesContext);    
  const [datos,setDatos]= useState([])
  const [espera,setEspera]= useState(false)
  const [esperaList,setEsperaList]= useState(false)
  const [esperaCump,setEsperaCump]= useState(false)
  const [esperaMuni,setEsperaMuni]= useState(false)
  const [esperaSecc,setEsperaSecc]= useState(false)
  const [listMuni, setListMuni] = useState([]);
  const[listSecc, setListSecc] = useState([])    
  const[listManzana, setListManzana] = useState([])    
  const[listSudoDet, setListSudoDet] = useState([])
  const[listSudoCumpl, setListSudoCumpl] = useState([])
  const[listTotal, setListTotal] = useState([])    
  const [regisAgrup, setRegisAgrup] = useState([]);
  const [regisTot, setRegisTot] = useState([]);
  const [tituloDirecc, setTituloDirecc] = useState("");
  const [checkCumple, setCheckCumple] = useState(false);
  const [etiqMuni, setEtiqMuni] = useState("");
  const [filtros,setFiltros]= useState({edo:15, muni:'', Seccion:'', manzana:''})
  const{edo, muni, Seccion, manzana}=filtros
  
  useEffect(()=>{
    
    const veriSesion =  () => 
    { if(usu==null)
      {                                
        guardarUsuario([])						              
        localStorage.clear();           
        history.push("/")	
      }
      else
      { if (usuario.length===0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
        getListMuni(15) 
        guardarGenerales({...generales,Titulo:'Carga de Trabajo'})   
      }
    //  getListTotal()
    }
    veriSesion()    
    
    return ()=> {
      source.cancel();
    }
  },[])

/*   useEffect(()=>{
    if (edo) 
    { getListMuni(edo)             
    }
  },[edo]) */

  useEffect(() => {
    if (muni) 
    {
      getListSecc(muni)    
      const filtro=listMuni.filter((munici)=>munici.Id===muni)
      console.log(filtro);
      //let filtrado = listOrig.filter((auxApoyo) => auxApoyo.TipoId === element.Id)
      setEtiqMuni(filtro[0].Nom)             
    }
        
  }, [muni])
  
  useEffect(() => {
    if (Seccion) 
    {
      //getListManzana(Seccion)        
      getListSudoDet2(Seccion)         
    }
        
  }, [Seccion])

  useEffect(() => {
    if (checkCumple && muni!=="") 
    {
      //getListManzana(Seccion)        
      getListSudoCumpl(muni)         
    }
        
  }, [checkCumple,muni])

  function getListMuni(auxEdo)  {            
    const url= `lugar-municipio`;                            
    const data = qs.stringify({                
      usuario:usuID, estado:auxEdo, idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    { if (aux[0].Id != -1) 
      { setListMuni(aux)
      }  
      else{
        setListMuni([])
      }                                                                    
    }
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaMuni, history, source);
  };

  const getListSecc = (auxMuni) => {
    const data = qs.stringify({
      usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
    });
    const url = "lugar-seccion";

    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSecc(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaSecc, history, source);
  }

  const getListSudoDet2 = (auxSecc) => {
    const data = qs.stringify({
      estado:edo, seccion:auxSecc, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku/detalle"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id !== -1) {                
        setListSudoDet(auxiliar)
        const auxTitulo=obtenTituloDir2(auxSecc)
        setTituloDirecc(auxTitulo)
        setRegisAgrup([])
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaList, history, source,urlExt);
  }

  const getListSudoCumpl = (auxMun) => {
    const data = qs.stringify({
      Estado:edo, Municipio:auxMun, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster.redvargas.mx/api/padron/v1/aniversario"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id !== -1) {                
        setListSudoCumpl(auxiliar)
     //console.log(auxiliar);
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaCump, history, source,urlExt);
  }

/*   const getListTotal = () => {
    const data = qs.stringify({
      tipo:2, llave:15, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListTotal(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  } */

  const filtroSlct=(e)=>{
    let arr=[]
    switch (e.target.name) {             
      case 'edo':
        arr={...filtros, edo:e.target.value,
          muni:"", Seccion:'', manzana:''}
        setListSecc([])
        setListManzana([])
        break
      case 'muni':
        arr={...filtros, muni:e.target.value, Seccion:'', manzana:''}
        setListManzana([])
        break
      case 'Seccion':
        arr={...filtros, Seccion:e.target.value, manzana:''}
        break
      default:
        arr= {...filtros, [e.target.name]:e.target.value}
        break;
    }  
    setFiltros(arr)
  }

  const onChangeSlct=(e)=>{      
    e.preventDefault()      
    if (e!== null && e!== undefined) 
    {   filtroSlct(e) 
    }
    else
    {   setFiltros({...filtros,[e.target.name]:""})          
    }   
    setListSudoDet([])     
  }

  const obtenTituloDir=(auxMan)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===Seccion)
    respuesta=`Sección ${filtrado[0].Numero} Manzana ${auxMan}`
    return respuesta
  }

  const obtenTituloDir2=(auxSecc)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===auxSecc)
    respuesta=`Sección ${filtrado[0].Numero} `
    return respuesta
  }

  const slctMuni =                        
    <SelectList lista={listMuni} value={muni} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Municipio"} nameEtq={"muni"}
      estilo={{minWidth:18+'rem',marginLeft:".5rem"}} 
    /> 

  const slctSecc =                        
    <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} nameEtq={"Seccion"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />  

/*   const slctManzana =                        
    <SelectList lista={listManzana} value={manzana} onChange={onChangeSlct} 
      etqVal={"Nom"} etqId="Nom" etqAlias={"Nom"} etiq={"Manzana"} nameEtq={"manzana"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />   */

  const onChangeCumple = () => {
      setCheckCumple(!checkCumple)
  };

  return (           
  <div style={{height:window.innerHeight-110 }} id="cajaSudo">
    <Box >
      <Typography style={{marginBottom: ".5rem",marginTop: "1rem"}}>  Busqueda por Secciones </Typography>                  
      <Box display={"flex"}>
       {/*  <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />       */}
        {esperaMuni ? <Box width={"15rem"} mt={3}><LinearProgress/></Box> : (listMuni.length!==0 ? slctMuni :null)}                      
        {esperaSecc ? <Box width={"15rem"} mt={3} ml={2}><LinearProgress/></Box> : (listSecc.length!==0 ?  slctSecc:null)}
        {/* listManzana.length!==0 ? slctManzana:null */}
        {/* <Box display="flex" alignItems="center" ml={3} >
          <FormControlLabel
              disabled={espera} label="Cumpleañeros" size={'small'}
              control={<Checkbox color="primary" checked={checkCumple} name={"checkCumple"}/>}
              labelPlacement="end" onChange={onChangeCumple}
          />                  
        </Box> */}
      </Box>
    </Box> 
    {esperaList?
      <Box width={"20rem"} mt={3}><LinearProgress/></Box>
    :
      (listSudoDet.length!==0 ?
        <Box display={"flex"}>
          <TablaAgrupamiento listAv={listSudoDet} registro={regisAgrup}  setRegistro={setRegisAgrup} tituloDirecc={tituloDirecc}/>
          {listSudoDet.length!==0 && regisAgrup.length!==0?
            <Box>
              <TablaPromovidos listAv={regisAgrup} />
            </Box>
          :null}
        </Box>
      :null)      
    }
  {esperaCump?
   <Box width={"20rem"} mt={3}><LinearProgress/></Box>
   :
   (checkCumple && listSudoCumpl.length!==0?
   <Box mt={2}> 
    <Typography>{`Cumpleañeros de ${etiqMuni}`}</Typography>
    <TablaCumple lista={listSudoCumpl} />
   </Box>
   :null)}
    {/* <Reporte urlReport={"reports/RepSudoku240130.mrt"} informacion={RepSudoku240130} nombre={' Reporte Sudoku'}   />   */}            
  </div>                           
  )
}

export default RepoSudoku