import React,{useState,useEffect,useContext} from 'react';
import {Box,//LinearProgress,
    IconButton,Tooltip, 
    Breadcrumbs,Typography, LinearProgress} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import Graficas from './GraficasRegion';
import CardTotal from './CardTotal';
import MapaGeneral from '../MapaEdo/MapaGeneral';
import TablaMun from './TablaMun';
//import GraficaPolar from './GraficaPolar';
import IntervaloSelect from '../perfiles/IntervaloTabla';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from 'moment';
import AvanceRecorrido from '../AvanceRecorrido';
//import RepGeoJson from "../MapaEdo/data/REPMEX.geojson";
import {ordenIdColor, } from "../MapaEdo/OrdenxMapBox"
import TablaSeccion from './TablaSecc';
import BusqSudoku from '../busqSudoku';
//import {arreglo} from './solicitudAvance'
import MapaPanelMunicipio from '../AvGeneral/MapaMunicipio';
const RepGeoJson="https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/REPMEX.geojson"
const useStyles = makeStyles({
 
    textoSelect: {
        '&:hover': {
          color:'#9ccdfa',
        },
        fontSize:1.25+'rem'
    },
  });
const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const AvanceSolicitud = () => {
    const classes = useStyles();
    const history = useHistory();
    const ColBase=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const auxMuni = process.env.REACT_APP_MUNI
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');          
    const usu= localStorage.getItem('UsuarioNom');   
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo') :'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
    const [listTotl, setListTotl] = useState([])                   
    const [listAv, setListAv] = useState([])                
    const [espera, setEspera] = useState(false)             
    const [registro, setRegistro] = useState({
        Id:15,Num:"México", Lat: 19.34046014155141, Lon: -99.62728632931811,
        url:"https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/15/15_EdoMex.geojson"}
    )      
    const [regMun, setRegMun] = useState(null)            
    const [listAvMun, setListAvMun] = useState([])        
    const [listGralMap, setListGralMap] = useState([])        
    const [listMunMap, setListMunMap] = useState([])     
    const [listGralOrg, setListGralOrg] = useState([])        
    const [listAvSecc, setListAvSecc] = useState([])        
    const [mapRegis, setMapRegis] = useState(null)   
    const [muniRegis, setMuniRegis] = useState(null)        
    const [long,setLong] = useState(-99.62728632931811);
    const [lat,setLat] = useState(19.34046014155141);
    const [zoom, setZoom] = useState( 7);
    const [fSelect, setFSelect] = useState({inicio:new Date("2023-01-01T00:00:00"),final:new Date()});
    const [buscar, setBuscar] = useState(false);
    const [tipoTabla, setTipoTabla] = useState(2);
    const [opcion,setOpcion] = useState(1)

    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }                       
            let auxArregloColor=[], auxArregloColor2=[]
            if (125 > arreColores.length) 
            {    
                for (let index = 0; index < 125; index++) {                    
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:index + 1 ,auxColor:arreColores[nivel]})      
                }                         
                /* aux.forEach((element,index )=> {
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                }); */
                for (let index = 0; index < arreColores.length; index++) 
                {
                    let auxPos=[]
                    const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                    filtrado.forEach((color,indice)=>{                        
                        auxPos[indice] = ordenIdColor(color.pos)//`${color.pos}`
                    })              
                    auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})      
                }
                //console.log(auxArregloColor2);    
                setListMunMap(auxArregloColor2) 
                setListGralMap(auxArregloColor2)
                setListGralOrg(auxArregloColor2)
            }  
           // guardarGenerales({...generales,Titulo:'Avance Solicitudes'})      
            llenaTotales(2,15,0)            
          //  llenaListDistr()
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[buscar]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {            
            if (registro!==null &&  registro?.Id!==0) 
            {
                //console.log(registro);
                llenaTotales(2,registro.Id,0)

                //const filtro =listTotl[0].estado.filter((edo)=>edo.EdoId===registro.Id)
                //setListAv(filtro)             

                let editable=listGralOrg.filter(obtenPos )
                if (editable.length!==0) 
                {   let nvo=[]
                    listGralOrg.forEach(element => {
                        let aux=element
                        if (Array.isArray(element.pos) && element.pos.length === editable[0].pos.length && element.pos === editable[0].pos) 
                        {   let nvoPos=[]
                            for (let index = 0; index < element.pos.length; index++) {
                                
                                if (element.pos[index] === `${registro.Id}`) {                                
                                    nvo.push({pos:[`${registro.Id}`],auxColor:ColBase})
                                }else{
                                    nvoPos.push(element.pos[index])
                                }
                                
                            }
                            if (element.pos === editable[0].pos) {
                                aux={pos:element.pos,auxColor:ColBase}
                            }  
                         
                            aux={pos:nvoPos,auxColor:element.auxColor}
                           // console.log(aux);
                             
                        }
                        else
                        {   if (typeof element.pos === 'string' || element.pos instanceof String)
                            {   if (element.pos === `${registro.Id}`) 
                                {   aux={pos:[`${registro.Id}`],auxColor:ColBase}
                                }
                            }
                        }
                        
                        nvo.push(aux)
                    });
                   //console.log(nvo);
                    //setListGralMap(nvo)
                }  
             const coord=listTotl[0].estado.filter((dist)=>dist.EdoId ===registro.Id)
                if (coord.length!==0) {
                    setLong(coord[0].Lon) 
                    setLat(coord[0].Lat)
                    setZoom(5)
                }  
               //  console.log(coord);
            }else{
                //setListGralMap(listGralOrg)
              //  console.log("limpiando");
                setListAv(listTotl[0].total)  
                setListAvMun([])
                setRegMun(null)
                setLong(-103.2993503) 
                setLat(23.5120348)
                setZoom( 3.7 )
            }    
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registro]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {
            if (mapRegis!==null  ) 
            {   const filtrado = listTotl[0].estado.filter((edo)=> edo.EdoId===parseInt(mapRegis.CVEGEO))
                setRegistro({Id:parseInt(mapRegis.CVEGEO),Num:mapRegis.NOMGEO, 
                    Lat: filtrado[0].Lat, Lon: filtrado[0].Lon,url:filtrado[0].Geo})
                setTipoTabla(2)
                let auxArregloColor2=[],auxArregloColor=[]
                if (filtrado[0].Total > arreColores.length) 
                {    
                    for (let index = 0; index < filtrado[0].Total; index++) {                    
                        const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                        auxArregloColor.push({pos:index + 1 ,auxColor:arreColores[nivel]})      
                    }                         
                    /* aux.forEach((element,index )=> {
                        const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                        auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                    }); */
                    for (let index = 0; index < arreColores.length; index++) 
                    {
                        let auxPos=[]
                        const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                        filtrado.forEach((color,indice)=>{                        
                            auxPos[indice] = ordenIdColor(color.pos)//`${color.pos}`
                        })              
                        auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})      
                    }
                    // console.log(auxArregloColor2);    
                    setListMunMap(auxArregloColor2)                 
                }  
               // llenaTotales(2,mapRegis.DTO_ID)
             
            } 
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mapRegis]);

    useEffect(() => {
        if (regMun) 
        {   setListAvSecc([])                
            llenaTotales(3,registro.Id,regMun.MunId)
        }
     
    }, [regMun])
    
    const reiniciar=()=>{
        setTipoTabla(2)
        setListTotl([])                   
        setListAv([])                                
        setRegistro({ Id:15,Num:"México", Lat: 19.34046014155141, Lon: -99.62728632931811,
        url:"https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/15/15_EdoMex.geojson"})      
        setRegMun(null)            
        setListAvMun([])        
        setListGralMap([])
        setListAvSecc([])                
        setMapRegis(null)        
        setLong(-103.2993503);
        setLat(23.5120348);
        setZoom( 3.7 );        
        setBuscar(!buscar);
    }
    
    function obtenPos(num) {
        let bandera=false
        //console.log(num);
        for (let i = 0; i<num.pos.length ; i++) {
            const pos=num.pos[i]
          //  console.log(pos);
          if (pos=== `${registro.Id}`) {
            bandera=true;
          }
        }
        return bandera;
    }

    const llenaTotales = (auxTipo, auxEdo, auxMun) => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");         
        const data = qs.stringify({
            usuario: auxId,                             
            status:0,
            estado:15,
            municipio:auxMun,
            seccion:0,
            otro:0,
            fini:fInicio+'T00:00:00',
            ffin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "solicitud/avance";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {   if (auxTipo===1) 
                {   setListAv(response[0].total)   
                    setListTotl(response) 
                    setTipoTabla(1)
                }
                else
                {   if (auxTipo===2) 
                    {   setListAvMun(response)
                        setListAv(response[0].total)  
                    }
                    else
                    {   setListAvSecc(response)
                    }
                }                                           
            }
            //console.log(response[0].total);
        }
        
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };

    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();        
        setOpcion(auxValor)
    }

    const menu=(
        <Box display="flex" justifyContent="center">
        <Breadcrumbs separator="-" aria-label="breadcrumb"  >
            <Typography variant="h5" color={opcion === 1 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,1)}
            >
                Promovidos
            </Typography>
            <Typography variant="h5" color={opcion === 2 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,2)}
            >
                Recorridos
            </Typography>    
      {     <Typography variant="h5" color={opcion === 3 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,3)}
            >
                Sudoku
            </Typography>      }                                                                    
        </Breadcrumbs>
        </Box>
    )

    const funcionRegresar=()=>{
        setTipoTabla(1)
        setRegistro({Id:0,Num:0}) 
    }

    return (
    <div style={{display:"flex",flexDirection:"column"}}>
        {/* menu */}
        {  opcion === 1 ?  
        <Box>
            <Box display={'flex'} pb={1}>
                {/* <Box >
                    <IconButton color="primary"  aria-label="add circle" 
                        component="span" onClick={()=>setBuscar(!buscar)}>
                        <Tooltip title="Buscar">  <SearchIcon/> </Tooltip>
                    </IconButton>                                                                                                                              
                </Box> */}
                <Box >
                    <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={reiniciar}>
                        <Tooltip title="Reiniciar">
                            <ReplayIcon/>
                        </Tooltip>
                    </IconButton>                                                                                                                              
                </Box>
             
                <Box >
                    <IntervaloSelect  fSelect={fSelect}  setFSelect={setFSelect}/>
                </Box>
                
                {listAv.length !== 0 ?
                    <CardTotal valor={listAv[0]} />
                :null
                }
            </Box>
            
            {listAv.length !== 0 ? 
                <Graficas valor={listAv} />
            :null }
            <Box display={"flex"} flexDirection={"row"} >
                { listGralMap.length !== 0 && !registro.Lat ?
                    <MapaGeneral  arregloColor={listGralMap} setMapRegis={setMapRegis} 
                        long={long} lat={lat} zoom={zoom} url={RepGeoJson} etiqueta={'CVEGEO'}
                    />       
                :null}
                {  listMunMap.length !== 0 && registro.Lat ?
                    <MapaGeneral  arregloColor={listMunMap} setMapRegis={setMuniRegis} 
                        long={registro.Lon} lat={registro.Lat} zoom={7.2} url={registro.url}//"../MapaEdo/data/15_EdoMex.geojson"}
                        etiqueta={'CVE_MUN'}
                    />       
                :null }
                {/* <MapaPanelMunicipio estadoId={15} munId={auxMuni} /> */}
                {espera?<Box style={{width:"15rem"}} mt={3}><LinearProgress/> </Box>
                : listAvMun.length !== 0 || listTotl.length !== 0 ?
                    <TablaMun  listAvMun={listAvMun} registro={regMun} registroEdo={registro} 
                        setRegistro={setRegMun} tipoTabla={tipoTabla} 
                        listAvEdo={listTotl} funcionRegresar={funcionRegresar}
                    />       
                :null
                }
                {listAvSecc.length!==0 && regMun!==null  ?
                    <TablaSeccion listAv={listAvSecc} registro={{}} setRegistro={()=>{}} />
                :null}
             
            </Box>            
        </Box>  
        :  (opcion === 2 ? 
                <AvanceRecorrido /> 
            :<BusqSudoku  />)
        }      
    </div>
  )
}

export default AvanceSolicitud