import React,{useState,useEffect} from 'react'
import {Box, TextField, FormControl, Tooltip, LinearProgress,
    FormControlLabel, FormLabel, RadioGroup, Checkbox, Card,
    Typography, Radio} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { format } from 'date-fns'
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import MapaAlerta from '../perfiles/MapaAlerta'
import {llamadaApiToken,ErrorGeneral,llamadaApiCarga,llamaApiCargaToken } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import { obtenDispo } from '../funciones/IdDispositivo';
//import SelectEdo from '../generales/SelectEdo';
import SelectList from '../generales/SelectList';
import ModalAvisoValida from './ModalAvisoPerfil';

const ModalPerfilv2 = ({modalAbierto,setModalAbierto ,titulo,listPlantilla,setModalAvisoImg,setNvoRegisPerfil, listReferente}) => {
    let history = useHistory();      
    const auxDispV =obtenDispo() 
    const source = axios.CancelToken.source();   
    const usuId =localStorage.getItem('UsuId') ;  
    const fechaMax = moment(new Date()).subtract(18,'years')          
    //const fechaMax = moment("2005-06-30T00:00:00")       
    const[perfil,setPerfil]= useState({Nombre:'', FNacimien:null, Email:'@', calle:'',
                                                Tel:'', ine:"", Municipio:null, Seccion:null, plantilla:0,
                                                edo:null, referente:"", genero:"H", apPat:"", apMat:"",ManualCheck:false
                                            });      
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [aviso, setAviso] = useState(false);    
    const[posicion,setPosicion]= useState({Lat:0,Lon:0})
    const[mensajePos,setMensajePos]= useState("")
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[nvoRegistro, setNvoRegistro] = useState([])       
    const[arrError,setArrError] = useState([false,false,false,false,false,false])     
    const[listMun, setListMun] = useState([])   
    const[listSecc, setListSecc] = useState([])      
    const[btnLeyenda, setBtnLeyenda] = useState("Validar")      
    const[modalVald, setModalVald] = useState(false)      
    const{Nombre, FNacimien, calle, Tel, ine, Municipio, Seccion, referente, genero, apPat, apMat, ManualCheck,plantilla}=perfil

    useEffect(()=>{   
        //setTimeout(() => { setModalVald(true) }, 2000); 
        //console.log(listPlantilla[0].Id);  
        setPerfil({...perfil,plantilla:listPlantilla[0].Id,
            edo: localStorage.getItem('prflEdo')??null,
           // Seccion: localStorage.getItem('prflSecc')??null,
        })
        //console.log(obtnFch());
        getListMuni(15)
      //  getLocation()
    },[])   

    useEffect(() => {
        if (Municipio) {
            getListSecc(Municipio.Id)
        }
            
    }, [Municipio])
  
    function getListMuni(auxEdo)  {            
        const url= `lugar-municipio`;                            
        const data = qs.stringify({                
            usuario:usuId,              
            estado:auxEdo,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMun(aux) 
                //filtroMun(aux)               
            }  
            else{
                setListMun([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const getListSecc = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuId, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                setListSecc(auxiliar)

            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    const filtroMun=(aux)=>{
        if (localStorage.getItem('prflMuni')) 
        {
            const filtro=aux.filter((mun)=>mun.Id ===parseInt(localStorage.getItem('prflMuni'))) 
            setPerfil({...perfil,Municipio:filtro[0]??null})
        }else{
            setPerfil({...perfil,Municipio:null,Seccion:null})
        }       
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  mr={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.3+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }

    const guardarFNaci = auxfecha =>
    {   
        setPerfil({ ...perfil,FNacimien : auxfecha  })
    };

    const onChange = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setPerfil({...perfil,[e.target.name] : e.target.value})            
        }		
	};             

    const onChangeNom = e =>
	{   let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setPerfil({...perfil,[e.target.name] : e.target.value})            
        }		
	}; 

    const verifiNombre = () => {                   
        const data = qs.stringify({
            cadena:`${Nombre.trim()} ${apPat.trim()} ${apMat.trim()}`,    
            idDispositivo: auxDispV,
        });
        const url = "perfil-verifica-cadena";
        function respuestaNombre(auxiliar){        
            const isEmpty = Object.keys(auxiliar).length === 0                        
            let auxArr=arrError
            if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
            {                             
                auxArr[3]=false 
                setArrError(auxArr)            
                validaDuplicado()              
            } 
            else 
            {   const auxmsj = "Nombre invalido, ingrese un Nombre diferente.";
                toast.warning(auxmsj,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${5}`
                })              
                mandaAlerta(auxmsj, "warning");              
                auxArr[3]=true                                
                setArrError(auxArr)
                setBtnDeshabilitado(false)           
            }         
        }
        llamadaApiCarga(data, url, respuestaNombre,ErrorGeneral,setEspera,history);
    };

    const obtnFch=()=>{
        return `${moment( FNacimien).format('YYYY-MM-DD')}T00:00:00` 
        //return `${format(FNacimien,'YYYY-MM-DD')}T00:00:00` 
    }   

    const validaDuplicado = () => {              
           
        const data = qs.stringify({
            nombre:`${Nombre.trim()} ${apPat.trim()} ${apMat.trim()}`,
            nacimiento:obtnFch(),
            municipio:Municipio.Id,
            idDispositivo: auxDispV,
        });
        const url = "perfil-verifica-duplicado";
        function respuestaDuplicado(auxiliar){        
            const isEmpty = Object.keys(auxiliar).length === 0
            let auxArr=arrError
            if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
            {         
                auxArr[3]=true     
                setArrError(auxArr)                             
                //getBusqPadron()    
                sinPadron()
                setBtnLeyenda("Guardar")
            } 
            else 
            {   const auxmsj = auxiliar.mensaje
                toast.warning(auxmsj, {pauseOnFocusLoss: false,                    
                    toastId: `nvo-dup-ver${1}`
                })                                    
                mandaAlerta(auxmsj, "warning");
                auxArr=arrError
                auxArr[3]=true                    
                setArrError(auxArr) 
                setBtnDeshabilitado(false)               
            }
        }
        llamadaApiCarga(data, url, respuestaDuplicado,ErrorGeneral,setEspera,history);
    };    
    
    const getBusqPadron = () => {                  
        const data = qs.stringify({            
            Estado:process.env.REACT_APP_EDO,
            Pat:apPat.trim(),
            Mat:apMat.trim(),
            Nom:Nombre.trim(),
            Fnac:obtnFch(),
            System:process.env.REACT_APP_Partd,
            idDispositivo: auxDispV,
        });
        const urlExt = "https://cluster.redvargas.mx/api/padron/v1/valida/nomfch";
        function respPadron(auxiliar){                
            let auxArr=arrError
            if ( auxiliar.mensaje.IdBase!=="0" ) 
            {              
                if (auxiliar.mensaje.Status===-1 ) {
                    auxArr[3]=true     
                    setArrError(auxArr)      
                    setBtnLeyenda("Guardar")                               
                    getNvoPerfil(auxiliar.mensaje.IdBase, auxiliar.mensaje.IdRg)      
                }
                else{
                    const auxMsj="Promovido ya registrado"
                    setMensaje(auxMsj)
                    toast.warning(auxMsj,{
                        pauseOnFocusLoss: false,                    
                        toastId: `val-err-perfil${1}`
                    }) 
                    mandaAlerta(auxMsj, "warning");                
                    setBtnDeshabilitado(false)  
                }
            } 
            else 
            {               
                if (auxiliar.mensaje.IdBase==="0" && auxiliar.mensaje.Status===-1) {
                    setBtnLeyenda("Guardar")                 
                    setModalVald(true)              
                    setBtnDeshabilitado(false)               
                }
                
            }
        }
        llamaApiCargaToken(data, "", respPadron, ErrorGeneral, setEspera, history, source, urlExt);
    };    

    const cerrarModAviso=()=>{        
        setModalVald(false)
        setBtnLeyenda("Validar")  
    }
    
    const sinPadron=()=>{      
        setModalVald(false)
        getNvoPerfil(0, 0) 
    }

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setPerfil({ ...perfil,[e.target.name] : e.target.value })            
            if (e.target.value.length===10) {
                setBtnDeshabilitado(false)                     
            }            
            else{
                setBtnDeshabilitado(true)                     
            }
        }		    

	}; 
     
    function getNvoPerfil(pdBase,pdRG)  {               
        const url=`perfil/v4/web/new`;    
        //const filtrado=listReferente.filter((refer)=> refer.Id===referente)                                                                                       
        const dataInfo = qs.stringify({                                                                               
            usuario:usuId,
            telefono:Tel,
            nombre:Nombre.trim(),    
            paterno:apPat.trim(),
            materno:apMat.trim(),
            email:"@",
            direccion:calle,            
            version:'1.0.0',
            plantilla:listPlantilla[0].Id ?? 1,
            nacimiento:obtnFch(),
            ine:ine,
            municipio:Municipio.Id,
            seccion:Seccion,
            referente:referente??0,
            latitud:posicion.length > 0 ? posicion.Lat : 0,
            longitud:posicion.length > 0 ? posicion.Lon : 0,
            genero:genero,
            Manual:ManualCheck?1:0,
            PadronID:pdRG,
            PadronINE:pdBase,
            idDispositivo:auxDispV
        });

        function respuesta (aux) {                                                                                 
            if (aux?.respuesta!==0) 
            {                               
                setBtnDeshabilitado(true)                                             
                const mensaje="Se registro correctamente el Perfil"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${1}`
                })
                
                setNvoRegisPerfil({Nom:`${Nombre} ${apPat} ${apMat}`,llave:aux.mensaje, PlantId:listPlantilla[0].Id ?? 1})
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
                
            }                                            
        }     
        llamaApiCargaToken(dataInfo, url, respuesta, ErrorGeneral, setEspera, history, source);  
    };

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[$@#_&-+()/.;!?,<>=%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setPerfil({ ...perfil, [e.target.name] : e.target.value.toUpperCase() })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};     

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if(campo===null|| (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                 
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
  
    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    const guardarMuni=(e)=>{    
        if (e!== null && e!== undefined) 
        {                            
            setPerfil({ ...perfil, Municipio : e  , Seccion :""  })      
            setMensaje('');                
            setAviso(false);             
            localStorage.setItem('prflMuni',e.Id)    
            localStorage.removeItem("prflSecc")   
        }else
        {   setPerfil({...perfil, Municipio : "" ,Seccion :""   })                       
        }        
    }

    const onchangeLocal=(e)=>{
        switch (e.target.name ) {
            case 'edo':                
                localStorage.setItem('prflEdo',e.target.value)     
                localStorage.removeItem("prflMuni")    
                localStorage.removeItem("prflSecc")     
                break;
            case 'Seccion':
                localStorage.setItem('prflSecc',e.target.value)        
                break;                                
            default:
                break;
        }        
    }

    const onChangeSlct=(e)=>{            
         if (e!== null && e!== undefined) 
        {   setPerfil({...perfil,[e.target.name]:e.target.value})   
            onchangeLocal(e)
        }
        else
        {   setPerfil({...perfil,[e.target.name]:""})          
        }        
    }

    const onChangeGen = e =>
    {  
        setPerfil({ ...perfil, genero: e.target.value  })        
    };
    
    const onChangeCheck = () => {
        setPerfil({...perfil,ManualCheck:!ManualCheck} )
    };

    function guardarDatos(e)
    {   e.preventDefault()                
        if (!verificaNulos(referente,"Referente") &&!verificaNulos(Municipio,"Municipio") &&!verificaNulos(Seccion,"Sección")  
            && validaVariable(Nombre,'Nombre',3) && validaVariable(apPat,'Apellido Paterno',3) 
        && validaVariable(apMat,'Apellido Materno',3) 
        &&!verificaNulos(FNacimien,"Fecha Nacimiento")     
        && validaVariable(Tel,'Telefono',10))
        {           
            setMensaje('');                
            setAviso(false);               
            setBtnDeshabilitado(true)              
            verifiNombre()                             
        }                                  
    } 
      
    const DatosGenerales=(
        <div>
            { listReferente.length!==0 ?                
                <Box display={"flex"} mb={2} >
                     <SelectList lista={listReferente} value={referente} onChange={onChangeSlct} 
                        etqVal={"Id"} etqId="Id" etqAlias={"Descr"} etiq={"Referente"} 
                        estilo={{ minWidth:14+'rem'}} nameEtq={"referente"}
                        tipo="outlined"  
                    />                 
                </Box> 
                :   null
             }                      
            <Box  mt={1} mb={1} display="flex" flexDirection={"row"}>       
                          
                {listMun.length!==0 ?
                <Autocomplete
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"           
                    noOptionsText={"Sin coincidencias"} options={listMun}  
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}                           
                  {listSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                     <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
                        etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} 
                        estilo={{marginLeft:1+'rem', minWidth:8.5+'rem'}} nameEtq={"Seccion"}
                        tipo="outlined"
                    />                 
                </Box> 
                :   null
                }
            </Box>            
           
            <TextField required id="Nombre-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]}  
            />
            
            <TextField required id="apPat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Apellido Paterno" value={apPat} name='apPat'size="small" autoComplete='off' // defaultValue="apPat"                
                //auxArrerror={arrError[3]}  
            />

            <TextField required id="apMat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem'}}
                label="Apellido Materno" value={apMat} name='apMat'size="small" autoComplete='off' // defaultValue="apMat"                
                //auxArrerror={arrError[3]} 
            />
            <br/>            
           
            <Box mt={1}>
              {/*   <SelectEdo edo={edo} onChangeSlct={onChangeSlct} tipo="outlined" /> */}
              {RelojFNacimiento()}
                <FormControl component="fieldset" size='small' style={{marginLeft:1+'rem'}}>          
                    <FormLabel  htmlFor="Género" size='small'>Género</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top" size='small'
                        name="genero" value={genero} onChange={onChangeGen}
                    >
                        <FormControlLabel value='H'  size='small'
                            control={<Radio color="primary" size='small'/>} label="Hombre" />    
                        <FormControlLabel value='M'   
                            control={<Radio color="primary" size='small' />} label="Mujer" />                         
                        <FormControlLabel value='X'   
                            control={<Radio color="primary" size='small' />} label="No Binario" />                         
                    </RadioGroup>                     
                </FormControl>
            </Box>  
          {/*  <TextField  id="ine-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:18+'rem',paddingRight:1+'rem',}}
                label="Ine"  value={ine} name='ine' size="small" autoComplete='off' inputProps= {{ maxlength: 18 }} 
            />   */} 
             <TextField  id="CInt-input" onChange={onChangeNumTel} style={{width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
           {/*  <br/>
            <FormControlLabel  style={{marginLeft:".5rem"}}
                disabled={espera} label="Escribir dirección" size={'small'}
                control={<Checkbox color="primary" checked={ManualCheck} name={"ManualCheck"}/>}                
                labelPlacement="end"  onChange={onChangeCheck}
            />
            <br/> */}
           {/* ManualCheck?
            <TextField  id="calle-input" onChange={onChange} style={{paddingBottom:1+'rem',width:30+'rem'}}
                label="Dirección"  value={calle} name='calle' size="small" autoComplete='off' //inputProps= {{ maxlength: 18 }} 
                maxRows={3} multiline variant='outlined'
            />:null    */}
            <br/>
            {/* posicion.length!==0?
                <Box style={{width:30.8+'rem',height:12+'rem'}}>
                    <Card style={{display:'flex'}}>
                    <MapaAlerta Seleccionado={posicion} 
                        tamanio={{ height: "12rem", width: "100%"}}
                    />
                    </Card>
                </Box>
            :<Typography variant='h6'>Dirección no disponible.
            {" "+ mensajePos?mensajePos:""}
            </Typography>
             */}
        </div> 
    )     

    const cerrarBorrar = () => {            
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {  
           setNvoRegisPerfil({Nom:Nombre,llave:nvoRegistro.mensaje})                                   
        }
    };
    
    const modNvoAviso = modalVald  ? <ModalAvisoValida modalAbierto={modalVald} setModalAbierto={setModalVald} 
                                        titulo="Validación" abrirModImg={()=>{}} getGuardar={sinPadron}
                                        actualizar={null} setActualizar={()=>{}} getCerrar={cerrarModAviso}
                                    >
                                        <Typography>No se encontro en la base de datos, ¿Desea guardar el Promovido?</Typography>
                                    </ModalAvisoValida>
                                        :null 

    return (           
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   btnLeyenda={btnLeyenda}
    >
        {modNvoAviso}
        {DatosGenerales}
    </Modal>             
    )
}

export default ModalPerfilv2