import React,{useEffect, useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,      
      TableContainer,TableHead,TableRow,Card,Tooltip,
      TablePagination,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import XLSX from 'xlsx';
import moment from 'moment';
import InfoPromo from './ModInfo';
import InfoIcon from '@material-ui/icons/Info';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import { obtenDispo } from '../funciones/IdDispositivo';
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:60+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[            
    {
      id:3, titulo:"Registro", alinear:'center'
    }, 
    {id:12,titulo:"Estado", alinear:'center'    },
    {id:8,titulo:"Municipio", alinear:'center'    },
    {
      id:7,titulo:"Sección", alinear:'center'
    },
    {
      id:6, titulo:"Teléfono",alinear:'center'
    }, 
    {
      id:10, titulo:"Referente",alinear:'center'
    },
    {
      id:11, titulo:"Nombre",alinear:'center'
    },
    {
      id:9, titulo:"Género", alinear:'center'
    }, 
    {
      id:5, titulo:"Verificación",alinear:'center'
    },     
    {
      id:4, titulo:"Nacimiento",alinear:'center'
    },       
  ];

const TablaPerfilGeneral = ({fSelect,actualizar,setActualizar,setArregloColor,setTotalPerfil,setInfoNvlCoord,nombre,edo, setListGeoEdo}) => {
  const classes = useStyles();
  let history = useHistory(); 
  const source = axios.CancelToken.source();    
  const tamMax=150
  const usuID = localStorage.getItem('UsuId')?parseInt(localStorage.getItem('UsuId') ):0;
  const fondo=process.env.REACT_APP_Fondo_Color
  const colSecond=process.env.REACT_APP_Color_Second
  const usuTipo =localStorage.getItem('Tipo') ?parseInt(localStorage.getItem('Tipo') ):0;              
  const auxDispV = obtenDispo()
  const [datos, guardarDatos] = useState([])  
  const[sinDatos,guardarSinDatos] = useState(true)
  const [espera,setEspera] = useState(false);
  const [selectedID, setSelectedID] = useState(null);  
  const [regisSelect, setRegisSelect] = useState([]);
  const [tituloMapa,setTituloMapa] = useState("")
  const [modalInfo, setModalInfo] = useState(false);    
  const [listPlantilla,setListPlantilla]=useState([])
  const [listaExcel, setListaExcel] = useState([]);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);

  useEffect(()=>
  {           
    conectarJSon();       
    if (listPlantilla.length===0) 
    {
      getListPlantilla()     
    }     
    getPerfilEstrutura()               
             
    getListGeoJson()

    return ()=> {
      source.cancel();
    }                  
  },[actualizar])

  const conectarJSon = () => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");             
    const data =  qs.stringify({        
          usuario:usuID,
          fini:fInicio+'T00:00:00',
          ffin:fFinal+'T23:59:59',
          status:0,        
          cadena:nombre!==""?nombre:"",            
          otro:"",            
          estado:edo!==""?edo:0,
          municipio:0,
          seccion:0,
          idDispositivo:auxDispV,
        })              
    //const url = encargado.length===0 || encargado?.UsrId===0?"perfil-lista-1-1" :"perfil-lista-encargado";
    const url ="perfil/publico/list"
    function respuesta(aux) {
      if (aux[0].Id !== -1 &&aux[0].Id !== 0&&aux[0].Id !== "0" ) 
      { guardarSinDatos(true)                                                           
        let auxPendiente=0, auxVerificado=0, auxRechazado=0
        let auxArrMuni=[],auxPos=[], arrM=[]
        arrM.push(["#","Municipio","Sección","Nombre","Teléfono","Género","Fecha Registro","Fecha Nacimiento","Foto"])
        aux.forEach((perfil,index) => {
          if (perfil.StatusId===1) 
          { auxPendiente=auxPendiente+1
          }
          if (perfil.StatusId===2) 
          { auxVerificado=auxVerificado+1
          }
          if (perfil.StatusId===3) 
          { auxRechazado=auxRechazado+1
          }
          let idColor= ordenIdColor(perfil.MunId )
          const auxMuni=perfil.MunNom?perfil.MunNom.charAt(0)+ perfil.MunNom.slice(1).toLowerCase() :""  
          let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
          let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
          arrM.push([index+1,auxMuni,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Genero,auxFReg,auxNac,perfil.Imagen])            
          let filtrado=auxPos.findIndex((elemt)=>elemt===idColor)           
          if (filtrado ===-1) {
            ordenMapBx(auxArrMuni,idColor,colSecond) 
            auxPos.push(idColor)
          }
          
        });          
        setListaExcel(arrM)
        setTotalPerfil({
          Valido:auxVerificado, Pendiente:auxPendiente, Rechazado:auxRechazado})  
        setArregloColor(auxArrMuni)
        numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)                     
        guardarDatos(aux)      
        
      }
      else
      { setListaExcel([])
        guardarDatos([])
        setListPagina([])
        guardarSinDatos(false)   
        setTotalPerfil([])    
        setArregloColor([])  
      }      
    }  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };   

  const getListGeoJson = () => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");             
    const data =  qs.stringify({        
          usuario:usuID,          
          status:0,                            
          estado:edo!==""?edo:0,
          municipio:0,
          seccion:0,
          otro:"",            
          cadena:nombre!==""?nombre:"",            
          fini:fInicio+'T00:00:00',
          ffin:fFinal+'T23:59:59',
          idDispositivo:auxDispV,
        })                  
    const url ="perfil/publico/geojson/list"
    function respuesta(aux) {
      if (aux[0].Id !== -1 &&aux[0].Id !== 0 ) 
      { let nvoEdo=[]
        aux.forEach((gjson)=>{
          nvoEdo.push(gjson)
        })
        setListGeoEdo(nvoEdo)
       
      }      
    }  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };   
  
  function getPerfilEstrutura()  {            
    const url= `perfil-estructura-perfil`;                            
    const data = qs.stringify({                
        usuario:usuID,          
        tipo:1,
        zon:0,
        reg:0,
        mun:0,
        sec:0,
        pro:0,                                           
        idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    {  if (aux[0].Id !==  -1 ) 
      {  //console.log(aux);  
        setInfoNvlCoord(aux)             
      }                                                                   
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
  };  

  function getListPlantilla()  {            
    const url= `plantilla-base-dominio`;                            
    const data = qs.stringify({                
        usuario:usuID,          
        dominio:process.env.REACT_APP_Dominio,                                         
        idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    {  if (aux[0].Id !==  -1 ) 
      {  //console.log(aux);  
        setListPlantilla(aux)               
      }                                                                   
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
  }; 

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination 
      rowsPerPageOptions={false}  style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista General.xlsx")
  }; 

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
    setRegisSelect(auxOrden)
  }      
  
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)    
    let titulo=`Registro ${auxSelec.Nombre} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  }
  
  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }

  const formatoNom=(cadena)=>{
    return cadena.charAt(0)+ cadena.slice(1).toLowerCase()
  }

  const obtenerGen=(aux)=>{
    let respuesta=""
    switch (aux) {
      case 'H':
        respuesta="Hombre"
        break;
      case 'M':
        respuesta="Mujer"
        break;
      case 'X':
        respuesta="Binario"
        break;
      case 'N':
        respuesta="Pendiente"
        break;
      default:
        break;
    }
    return respuesta
  }
  
  function Renglon(props)
  {
    const { row } = props; 
    const auxMuni=row.MunNom?formatoNom(row.MunNom) :""   
    const auxEdo=row.MunNom?formatoNom(row.EdoNom) :""   
    let auxNac=moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')    
    let auxFReg=moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')
    let leyenda=`Crear Imagen`
    let tipoColor="inherit"
    let auxValida=1
    if(row.Imagen.length > 0)
    {
      leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    
                       
    const auxInfo= <Tooltip title={`Información de registro`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => abrirModalInfo(row)} component="span">
        {selectedID === row.Id ? 
          <InfoIcon style={{color:'white',width:18+'px'}} />
          :<InfoIcon style={{width:18+'px'}} color="primary"/>
        }
      </IconButton>                     
      </Tooltip>                                                                                                                                                                            
    return (
    <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}      
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >      
      {celda("center",{width:10+'px'}, auxInfo)}
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxFReg)}
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxEdo) }
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxMuni) }      
      {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}        
      {celda("center",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}
      {celda("left",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.ReferNom)}  
      {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Nombre)}  
      {celda("center",{width:70+'px',fontSize:12+'px',paddingLeft:8+'px'}, obtenerGen( row.Genero))}                                                                                     
      {tipoEdoProspecto(row.StatusColor,row.StatusNom)}             
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxNac)}                                                                                                                                                                                   
    </TableRow>        
    </React.Fragment>
  );}
  
  const vacia=<TableCell  className={classes.tableCell} align="center"> </TableCell>
  const ColumnaVacia=<TableCell  className={classes.tableCell} align="center" style={{ backgroundColor:fondo,color:'white'}}  > </TableCell>

  function tablaCompleta(auxlista){ 
    return(
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>           
                                    
          <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >                          
            {auxlista.length}
          </TableCell>                           
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
            <Renglon key={"regPerfilGen"+row.Id} row={row} />
        ))):null}
      </TableBody>
    </Table>
    </TableContainer>
    )
  }
    
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center" padding={'none'}  style={{ backgroundColor:fondo,color:'white'}} >              
            {ColumnaVacia}           
          </TableCell>       
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}  
          {vacia}                
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>  
          {vacia}               
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}      
          {vacia}     
          {vacia}             
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  const contenido = sinDatos&&listPagina.length!==0 ?tablaCompleta(listPagina):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido ) 

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo}  listPerfil={datos}
                              setModalAbierto={setModalInfo} titulo={tituloMapa} /> : null;                                                                                                                           
  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                                                   

  return (
  <div style={{minWidth:55+'rem',maxWidth:65+'rem'}}>            
    {paginacion}      
    {tabla}           
    {modInfo}
  </div>
  )
}

export default TablaPerfilGeneral